import AsyncStorage from "@react-native-async-storage/async-storage"
import { currentDate, currentDateCalender, formatDateCalenderStyle } from "../Utils/DateTime"
import { SupabaseConfig } from "./SupabaseConfig"

const numberFix = (value)=>{
  if (typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value) ) {
      return value.toFixed(2);
  }
  return value;
}

export const storeFoodFullData = async(value) => {
    try {
        await AsyncStorage.setItem('foodData', JSON.stringify(value))
      } catch (e) {
        // saving error
      }
}

export const storeBulkFoodData = async (foodArray) =>{
  const { data, error } = await SupabaseConfig
  .from('foods')
  .insert(foodArray).select();
  if (error) {
    console.log("error: ", error);
      alert("Failed! "+ error.message)
      return false;
  }
  else{
    // console.log("data: ",data)
    return data;
  }
}

export const getFoodFullData = async(foodName=null) => {
  if (foodName) {
    let { data: foods, error } = await SupabaseConfig.from('foods').select('*').range(0, 9).ilike('food_name', `%${foodName}%`);
    return foods;
  }
  let { data: foods, error } = await SupabaseConfig.from('foods').select('*').range(0, 9);
  return foods;

// console.log("foods: ",foods);

    // try {
    //     const jsonValue = await AsyncStorage.getItem('foodData')
    //     // console.log("jsonValue: ",jsonValue);
    //     return jsonValue !== null ? JSON.parse(jsonValue) : null;
    //   } catch(e) {
    //     console.log("getFoodFullData: ", e)
    //   }
      
}


export const searchFoodFrom_EdamamApi = async (search) =>{
  const respApi = await fetch(`https://api.edamam.com/api/food-database/v2/parser?app_id=6c205bc9&app_key=5310b20e0defa4a302d63b4d6f1a00a7&ingr=${search}`); // await fetch(`https://api.nal.usda.gov/fdc/v1/foods/search?query=${search}&pageSize=100&api_key=Zpyl85SdSKOmevnZcU5oP5HnadxEhRt780D9qQPw`);
  const result = await respApi.json();
  // console.log("food result: ", result.hints)

  const filtered = result.hints.filter((item)=>{
      if (item.food.servingSizes) {
          return true;
      }
      return false;
  })
  console.log("filtered: ", filtered)

  if(filtered.length===0){
    return [];
  }

  var size = 3;

  const modified = filtered.slice(0, size).map((item)=>{
      console.log("item: ", item)
      var temp = {
          food_name:item.food.label
      }

      if (item.food.brand) {
          temp = {...temp, brand_name:item.food.brand}                    
      }


      const servingSizeGram = item.food.servingSizes.find(unit => unit.label==="Gram")
      const servingSizeOunce = item.food.servingSizes.find(unit => unit.label==="Ounce")
      // console.log("servingSizeGram: ", servingSizeGram)
      // console.log("servingSizeOunce: ", servingSizeOunce)
      
      var serving_size = {amount: (servingSizeGram)?servingSizeGram.quantity:Math.round(servingSizeOunce.quantity*28.35), unit:"g"}

      var food_nutrients = {
          calories: numberFix(item.food.nutrients.ENERC_KCAL),
          protein: numberFix(item.food.nutrients.PROCNT),
          fat: numberFix(item.food.nutrients.FAT),
          carbohydrate: numberFix(item.food.nutrients.CHOCDF),
          fiber: numberFix(item.food.nutrients.FIBTG)
      }

      temp = {...temp, food_nutrients:food_nutrients, serving_size:serving_size}
      return temp;

  })

  return modified;
}

export const searchFoodFrom_NutritionixApi = async (search) => {
  const headers = {
    'x-app-id': 'a8e55bd1',
    'x-app-key': '2484beb162bb86bb993530fea73ce8b5',
  };
  const respApi = await fetch(`https://trackapi.nutritionix.com/v2/search/instant?query=${search}&detailed=true&common=false`, { headers }); // await fetch(`https://api.nal.usda.gov/fdc/v1/foods/search?query=${search}&pageSize=100&api_key=Zpyl85SdSKOmevnZcU5oP5HnadxEhRt780D9qQPw`);
  const result = await respApi.json();

  // console.log("Nutritionix result: ", result)

  var size = 5;

  const modified = result.branded.slice(0, size).map((item)=>{
    // console.log("item: ", item)
      var temp = {
          food_name:item.food_name,
          brand_name:item.brand_name,
          brand_name_food_name: item.brand_name_item_name,
          nix_item_id: item.nix_item_id,
          serving_size: {amount: Math.round(item.nf_metric_qty), unit:"g"}
      }

      const calories = item.full_nutrients.find(nix_attr => nix_attr.attr_id===208);
      const protein = item.full_nutrients.find(nix_attr => nix_attr.attr_id===203);
      const fat = item.full_nutrients.find(nix_attr => nix_attr.attr_id===204);
      const carbohydrate = item.full_nutrients.find(nix_attr => nix_attr.attr_id===205);
      const fiber = item.full_nutrients.find(nix_attr => nix_attr.attr_id===291);

      var food_nutrients = {
        calories: calories?( calories.value ):0,
        protein: protein?( protein.value ):0,
        fat: fat?( fat.value ):0,
        carbohydrate: carbohydrate?( carbohydrate.value ):0,
        fiber:  fiber?( fiber.value ):0
    }

      return {...temp, food_nutrients:food_nutrients};

  });

  return modified;
}


export const removeFoodFullData = async () => {
    try {
        await AsyncStorage.removeItem('foodData')
    } catch(e) {
        // remove error
    }

    console.log('Done.')
}


export const storeDiaryFullData = async(value) => {
  const { data: { session } } = await SupabaseConfig.auth.getSession();
  const { error } = await SupabaseConfig.from('diary').insert({...value, user_id:session.user.id});
  if (error) {
    console.log("error: ", error);
      alert("Failed! "+ error.message)
      return false;
  }else{
      // console.log("Data: ", data);
      // alert("Success! \n Diary new row added..");
      return true;
      // navigation.navigate('Login')
  }
    // try {
    //     await AsyncStorage.setItem('diaryData', JSON.stringify(value))
    //   } catch (e) {
    //     // saving error
    //   }
}

export const updateDiaryData = async (rowId, values) =>{
  const { data: { session } } = await SupabaseConfig.auth.getSession();
  const { error } = await SupabaseConfig.from('diary').update(values).eq('id', rowId).eq('user_id', session.user.id)
  if (error) {
    console.log("error: ", error);
      alert("Failed! "+ error.message)
      return false;
  }else{
      // console.log("Data: ", data);
      // alert("Success! \n Diary row updated..");
      return true;
      // navigation.navigate('Login')
  }
}

export const getDiaryFullData = async(today=null) => {
  if(today){
    const { data: { session } } = await SupabaseConfig.auth.getSession();
    let { data: diary, error } = await SupabaseConfig.from('diary').select(`
    *,
    foods (
      *
    )`).eq('date', today).eq('user_id', session.user.id)
    
    if (diary) {
      return diary;
    }
  }
  return null;
  // else{
  //   let { data: diary, error } = await SupabaseConfig.from('diary').select("*").range(0, 9);
  // }
    // try {
    //     const jsonValue = await AsyncStorage.getItem('diaryData')
    //     // console.log("jsonValue: ",jsonValue);
    //     return jsonValue !== null ? JSON.parse(jsonValue) : null;
    //   } catch(e) {
    //     console.log("getDiaryFullData: ", e)
    //   }
      
}

export const getDiaryFullDataByDate = async(startDate=currentDateCalender, endDate=formatDateCalenderStyle(new Date().getTime() - 7 * 24 * 60 * 60 * 1000) ) => {
// console.log('startDate: ', startDate, ' endDate: ', endDate)

    const { data: { session } } = await SupabaseConfig.auth.getSession();
    let { data: diary, error } = await SupabaseConfig.from('diary').select(`
    *,
    foods (
      *
    )`).eq('user_id', session.user.id).gt('date', endDate).lte('date', startDate);
    
      return diary;
  // else{
  //   let { data: diary, error } = await SupabaseConfig.from('diary').select("*").range(0, 9);
  // }
    // try {
    //     const jsonValue = await AsyncStorage.getItem('diaryData')
    //     // console.log("jsonValue: ",jsonValue);
    //     return jsonValue !== null ? JSON.parse(jsonValue) : null;
    //   } catch(e) {
    //     console.log("getDiaryFullData: ", e)
    //   }
      
}

export const removeDiaryFullData = async () => {
    try {
        await AsyncStorage.removeItem('diaryData')
    } catch(e) {
        // remove error
    }

    console.log('Done.')
}