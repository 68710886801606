import react,{useState, useEffect} from 'react';
import { StyleSheet, Text, View,TextInput, ScrollView,SafeAreaView,Image,Alert, Button, FlatList,ActivityIndicator,TouchableOpacity } from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import {FontAwesome} from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import {BarCodeScanner} from 'expo-barcode-scanner';
import { Bar } from 'react-native-progress';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { getFoodFullData, searchFoodFrom_EdamamApi, searchFoodFrom_NutritionixApi, storeBulkFoodData, storeFoodFullData } from '../hooks/DiaryHook';
import { useRoute } from '@react-navigation/native';
import { screenWidth } from '../Utils/Dimensions';



// function Item({name,logo,selected, navigation}){
//     return(
        
      
//     )
//   }


//   {item.brandOwner},

/*
const DATA = [
    {
        name: 'Chicken',
        Energy :'107 cal',
        Serving : '56 gram',
        id:1
    },{
        name: 'Paratha',
        Energy :'107 cal',
        Serving : '56 gram',
        id:2
    },{
        name: 'Rice',
        Energy :'107 cal',
        Serving : '56 gram',
        id:3
    },{
        name: 'Mutton',
        Energy :'107 cal',
        Serving : '56 gram',
        id:4
    },{
        name: 'Aloo',
        Energy :'107 cal',
        Serving : '56 gram',
        id:5
    },{
        name: 'Kola',
        Energy :'107 cal',
        Serving : '56 gram',
        id:6
    },
      
    ]
*/

    const numberFix = (value)=>{
        if (typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value) ) {
            return value.toFixed(2);
        }
        return value;
    }

    function Item({name, brand, nutrients, selected, serving}){
        return(
            <View style={{flexDirection:'row', justifyContent:'space-between',margin:5, backgroundColor:'#183c57', borderRadius:10, paddingVertical:5, padding:15, width:'100%'}}>
                <View style={{width: '90%'}}>
                    <Text style={{color:'#5ec49f', fontSize:19,}}>{name}</Text>
                    <Text style={{color:'#011', fontSize:16,}}>Brand: {brand}</Text>
                    
                    <Text style={{color:'#b2b1b9', fontSize:16,flexDirection:'column'}} >Calories : { numberFix(nutrients.calories)},  {serving.amount} {serving.unit} </Text>
                    
                </View> 
                   {selected == true && (<FontAwesome name='check-circle' size={35} color='green' />)}
            </View>
            
          
        )
      }
    


export default function AddFood({navigation}) {
    const [isLoading, setLoading] = useState(true)
    const [loadingText, setLoadingText] = useState("");
    const [search, setSearch] = useState('chicken');
    const [data, setData] = useState([]);
    const [eng, setEng] = useState([]); 
    const [scnd, setScnd] = useState([]);
    const [foodList,setFoodList] = useState([])
    const route = useRoute();

    const [mealType, setMealType] = useState(null);


    // const fetchScanData = async(scanData) =>{
    //     const resp = await fetch(`https://world.openfoodfacts.org/api/v0/product/${scanData}.json`);
    //     const data2 = await resp.json();
    //     // console.log(data2)
    //     setScnd(data2)
    //     // setData(data2.foods);
    //     // setEng(data.foodNutrients);
    //     setLoading(false);
    //     // console.log(data2.code)
    //   };

     
      




    // const handleBarCodeScanner = (scanData) =>{
    //     setSearch(scanData);
    //     // console.log("add food data", scanData)
    //     fetchScanData(scanData)
    //     // console.log("scan Data from handleBarcodeScanner" , scnd)
    //     console.log(scnd)
    // }


   
    const onLoad = async () =>{
        setLoading(true);
        // AsyncStorage.setItem('setFood', JSON.stringify(DATA))
        // storeFoodFullData(DATA);
        var newDataArr = await getFoodFullData();
        // var newDataArr = DATA;
        console.log("newDataArr: ", newDataArr);
        newDataArr = newDataArr.map(v => ({...v, selected: false}))
        setFoodList(newDataArr);
        if (route.params.MealType) {
            setMealType(route.params.MealType);
            // console.log("meal type: ", route.params.MealType);
        }
        setLoading(false)
        // console.log('foodlist', foodList)
    }

    const onSearchLoad = async () =>{
        setLoading(true);
        setLoadingText("Fetching...");
        // AsyncStorage.setItem('setFood', JSON.stringify(DATA))
        // storeFoodFullData(DATA);
        var newDataArr = await getFoodFullData(search);
        // var newDataArr = DATA;
        // console.log("newDataArr: ", newDataArr);
        newDataArr = newDataArr.map(v => ({...v, selected: false}))
        setFoodList(newDataArr);
        if (route.params.MealType) {
            setMealType(route.params.MealType);
            // console.log("meal type: ", route.params.MealType);
        }

        if (newDataArr.length===0 || newDataArr===null) {
            
            /*
            const respApi = await fetch(`https://api.edamam.com/api/food-database/v2/parser?app_id=6c205bc9&app_key=5310b20e0defa4a302d63b4d6f1a00a7&ingr=${search}`); // await fetch(`https://api.nal.usda.gov/fdc/v1/foods/search?query=${search}&pageSize=100&api_key=Zpyl85SdSKOmevnZcU5oP5HnadxEhRt780D9qQPw`);
            const result = await respApi.json();
            // console.log("food result: ", result.hints)

            const filtered = result.hints.filter((item)=>{
                if (item.food.servingSizes) {
                    return true;
                }
                return false;
            })
            console.log("filtered: ", filtered)

            var size = 4;

            const modified = filtered.slice(0, size).map((item)=>{
                // console.log("item: ", item)
                var temp = {
                    food_name:item.food.label
                }

                if (item.food.brand) {
                    temp = {...temp, brand_name:item.food.brand}                    
                }


                const servingSizeGram = item.food.servingSizes.find(unit => unit.label==="Gram")
                const servingSizeOunce = item.food.servingSizes.find(unit => unit.label==="Ounce")
                // console.log("servingSizeGram: ", servingSizeGram)
                // console.log("servingSizeOunce: ", servingSizeOunce)
                
                var serving_size = {amount: (servingSizeGram)?servingSizeGram.quantity:Math.round(servingSizeOunce.quantity*28.35), unit:"g"}

                var food_nutrients = {
                    calories: numberFix(item.food.nutrients.ENERC_KCAL),
                    protein: numberFix(item.food.nutrients.PROCNT),
                    fat: numberFix(item.food.nutrients.FAT),
                    carbohydrate: numberFix(item.food.nutrients.CHOCDF),
                    fiber: numberFix(item.food.nutrients.FIBTG)
                }

                temp = {...temp, food_nutrients:food_nutrients, serving_size:serving_size}
                return temp;

            })
            */

            
            setLoadingText("Fetching from external food database...");

           const firstAPI = await searchFoodFrom_NutritionixApi(search);
           if (firstAPI.length>0) {
               console.log("firstAPI: ", firstAPI)

               if (firstAPI.length>0) {
                   const createdResp = await storeBulkFoodData(firstAPI)
                   // console.log("createdResp: ", createdResp);
                   if (createdResp && createdResp.length>0) {
                        setFoodList(createdResp);
                    }
               }

                
            }else{
                setLoadingText("Fetching from external food database...\nJust a second!");
                const secondAPI = await searchFoodFrom_EdamamApi(search);
                console.log("secondAPI: ", secondAPI)
                if (secondAPI.length>0) {
                    const createdResp = await storeBulkFoodData(secondAPI)
                   // console.log("createdResp: ", createdResp);
                    if (createdResp && createdResp.length>0) {
                        setFoodList(createdResp);
                    }
                    
                }
            }

        }
        setLoadingText("")
        setLoading(false)
        // console.log('foodlist', foodList)
    }



    // const fetchData = async ({search}) => {
    //     const resp = await fetch(`https://api.nal.usda.gov/fdc/v1/foods/search?query=${search}&pageSize=5&api_key=Zpyl85SdSKOmevnZcU5oP5HnadxEhRt780D9qQPw`);
    //     const data2 = await resp.json();
    //     setData(data2.foods);
    //     // setEng(data.foodNutrients);
    //     setLoading(false);
    //   };
    // //   console.log(data)
    //   const fetchNutrition = (nutrition) => {
    //     // isLoading && console.log(data.foodNutrients);
    //     const energy = nutrition.find(item => {
    //       return (item.nutrientName.toLowerCase().indexOf("energy") !== -1)&& item;
    //     })
    //     const carbohydrate = nutrition.find(item => {
    //       return (item.nutrientName.toLowerCase().indexOf("carbohydrate") !== -1)&& item;
    //     })
    //     const fat = nutrition.find(item => {
    //       return (item.nutrientName.toLowerCase().indexOf("fat") !== -1)&& item;

    //     })
        
    //     // onLoad()
    //     return [energy, carbohydrate, fat];
       
    //   };


      const handleOnPress = (item) =>{
        const newItem = foodList.map((val)=>{
            // console.log(foodList)
          if (val.id==item.id) {
            // setSelected([...selected, item]);
            return {...val, selected:!val.selected}
          } else {
            return val;
          }
        })
        // console.log(item);
        setFoodList(newItem);
      }
    

      const handleSubmit = () =>{
        const filteredData = foodList.filter(val=> val.selected==true);
        // console.log(filteredData);
        navigation.navigate('AddFood2', {SelectedFood:filteredData, MealType: mealType});
      }


    
    useEffect(()=>{
        // fetchData({search})
        // fetchNutrition({data})
        onLoad()
        

    },[]);

  

    
    return(
        <View style={styles.container}>
            <View style={{ borderWidth:0.5, marginTop:10,borderColor:'#bdbdbd', borderRadius:25, flexDirection:'row',justifyContent:'space-between', backgroundColor:'#000',marginBottom:5}}>
                <FontAwesome name='search' style={{fontSize:16 ,flexDirection:'row', padding: 15, color:'grey'}}/>
                <TextInput placeholder='Search For A Food' placeholderTextColor='#b2b1b9' style={{fontSize:16, color:'#b2b1b9', width:'100%', borderWidth: 0, borderColor: 'transparent', paddingLeft:5}} underlineColorAndroid='transparent' onChangeText={text => setSearch(text)}></TextInput> 
                {/* <FontAwesome name='camera' onPress={()=>{navigation.navigate('ScanBarcode', {handleBarCodeScanner:handleBarCodeScanner})}}  style={{fontSize:16 ,flexDirection:'row', padding: 15, color:'grey', justifyContent:'flex-end'}}/> */}
            </View>
            <Button title='Search' onPress={()=>{onSearchLoad()}}/>
            {/* <Button title='Search2' onPress={()=>{handleBarCodeScanner({scnd})}}/> */}
            

           

            <View style={{margin:20}}>
                <View style={{flexDirection:'row', justifyContent:'space-between',}}>
                    <TouchableScale onPress={()=>{navigation.navigate('SearchFood')}}>
                    <Text style={{fontSize:20, color:'#bdbdbd', paddingTop:8}}>History</Text>

                    </TouchableScale>
                    <View style={{borderWidth:0.5, borderColor:'#bdbdbd', borderRadius:25,padding:10}}>
                        <Text style={{fontSize:20, color:'#b2b1b9',flexDirection:'row',}}>
                        <FontAwesome name='pie-chart' style={{fontSize:20 ,flexDirection:'row', padding: 10, color:'grey',  }}/>
                            Most Recent</Text>
                    </View>
                </View>
            </View>



            {isLoading===true ? (<><View><ActivityIndicator/></View><View style={{ flexDirection:'row', justifyContent:'center'}}><View style={{width:'75%',}}><Text style={{color:'#5ec482', textAlign:'center'}}>{loadingText}</Text></View></View></>):(
                <>

                {foodList.length>0?(
                    <>
                    <FlatList
                    data = {foodList}
                    renderItem={({item})=> {
                    return( 
                        <TouchableOpacity   onPress={()=>handleOnPress(item)}>
                            <Item name = {item.food_name} brand={item.brand_name?item.brand_name:"Generic Food"} nutrients = {item.food_nutrients} serving={item.serving_size} selected={item.selected}/>
                        </TouchableOpacity>
                        
                    )
                    }}
                    keyExtractor={item=>item.id}
                    />
                    
                    <View>
                        <Button title='Add Data' onPress={()=>{handleSubmit()}}> Add </Button>
                    </View>
                    </>
                    ):(<View style={{flexDirection:'row', justifyContent:'center'}}><Text style={{color:'#b04d20', fontWeight:'bold'}}>No food found with this keyword...</Text></View>)
                }
                </>
             )}



            {/* {isLoading ? (<ActivityIndicator/> ):( 
                <FlatList
                data = {scnd}
                renderItem={({item})=> {

                    console.log('Scan Data From Flatlist ', item)
                    const n1 = fetchNutrition(item)
                    console.log("nutrition print: ",n1);
                    setEng(item.foodNutrients)
                  return(
                    <View style={{margin:5, backgroundColor:'#183c57', borderRadius:10, paddingVertical:5}}>
                        <View style={{backgroundColor:'#183c57',padding:10, borderRadius:10, marginBottom:15}}>
                            <TouchableScale>
                                <View style={{flexDirection:'row',justifyContent:'space-between'}}>
                                    <View style={{flexDirection:'column'}}>
                                        <Text style={{color:'#5ec49f', fontSize:19,flexDirection:'column'}}>{item._id}</Text>
                                        
                                        <Text style={{color:'#b2b1b9', fontSize:16,flexDirection:'column'}} >{n1[0].nutrientName}: {n1[0].value} cal, {item.brandOwner}, {item.servingSize} gram</Text>
                                    </View>
                                </View>
                            </TouchableScale>
                        </View>
                    </View>
                  )
              }}
                keyExtractor={item=>item.id}
                />
            )} */}




                    

                    
        
        </View>
    )
}



const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
        padding:10,
        width: screenWidth,
    },

})