import react,{useEffect, useState} from 'react';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View, TextInput, FlatList , TouchableOpacity, ScrollView, ActivityIndicator, Alert} from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
// import TouchableScale from 'react-native-touchable-scale';
import { useRoute } from '@react-navigation/native';
import {currentDate, currentDateCalender} from '../Utils/DateTime'
import { storeExerciseFullData, getExerciseFullData, removeExerciseFullData } from '../hooks/ExerciseHook';
import { getDiaryFullData, getFoodFullData, removeDiaryFullData, storeDiaryFullData, updateDiaryData } from '../hooks/DiaryHook';
import TouchableScale from 'react-native-touchable-scale';

const numberFix = (value)=>{
    if (typeof value === 'number' && !Number.isNaN(value) && !Number.isInteger(value) ) {
        return value.toFixed(2);
    }
    return value;
}

export default function AddFood2({navigation}) {
    const [loading, setLoading] = useState(true)
    const route = useRoute();
    const [selectedFood, setSelectedFood] = useState(null)
    const [mealType, setMealType] = useState(null)
    const [mealData, setMealData] = useState(null);

    const [disabledButtons, setDisabledButtons] = useState([]);
    
    
    const [getStoredFoodFullData, setGetStoredFoodFullData] = useState(null);
    
    console.log("route.params.SelectedFood: ",route.params.SelectedFood);
    const [diaryData, setDiaryData] = useState(null)
    const loadData = () =>{
        // getFoodFullData().then((foods)=>{
            setSelectedFood(route.params.SelectedFood);
            setMealType(route.params.MealType);
            // if (foods) {
                getDiaryFullData(currentDateCalender).then((diaries)=>{
                    // let diaryDetailsTemp;
                    let initialFoodSets;
                    // setGetStoredFoodFullData(diaries);
                    if (diaries.length>0) { //if already any data exists in database
                        console.log("route.params.SelectedFood: ", route.params.SelectedFood);
                        console.log("Today diaries: ", diaries);
                        

                        initialFoodSets = route.params.SelectedFood.map((item, i)=>{
                            // console.log("item: ", item);
                            const diaryFood = diaries.find(diary => diary.food_id === item.id);
                            console.log("diaryFood: ", diaryFood)
                            return {
                                foodID:item.id,
                                servingSize:diaryFood ? diaryFood.serving_data.amount : 0,
                                isExists:diaryFood?true:false,
                                rowId:diaryFood?diaryFood.id:null
                            }
                        })

                        // const findTodaysData = diaries.find(item=>item.date===currentDate)
                        // console.log("findTodaysData: ", findTodaysData.diaryDetails);
                        // diaryDetailsTemp = [...findTodaysData.diaryDetails, {mealType:route.params.MealType, foods:initialFoodSets}];
                        
                    }else{
                        initialFoodSets = route.params.SelectedFood.map((item, i)=>{
                            // console.log("item: ", item);
                            return {
                                foodID:item.id,
                                servingSize:0,
                                isExists:false
                            }
                        })
                        
                        // diaryDetailsTemp = initialFoodSets; //[{mealType:route.params.MealType, foods:initialFoodSets}];
                    }

                    setMealData({mealType:route.params.MealType, foods:initialFoodSets});

                    

                    
                    // console.log("initialFoodSets: ", initialFoodSets)
                    // console.log("diaryDetailsTemp: ", diaryDetailsTemp)

                    // setDiaryData(               
                    //     {
                    //         date:currentDate,
                    //         diaryDetails: diaryDetailsTemp,
                    //     }
                    // )
                    
                    setLoading(false)
                })
            // }
        // })
    }

    

    const handleServingSize = (onChangeServing, foodID) =>{
        
        console.log(mealType,"  mealData: ", mealData);
        // console.log("diaryData: ", diaryData);
        const foods = mealData.foods;
        const updatedData = mealData.foods.map((fData, index)=>{
            // console.log("fData: ", fData)
            if (fData.foodID===foodID) {
                const savedData = [...foods]
                savedData[index].servingSize=parseInt((onChangeServing.length>0)?onChangeServing:0)
            }
            return fData;
        })
        // const updatedDiaryDetails = diaryData.diaryDetails.map((item, i)=>{
        //     if (item.mealType===mealType) {
        //         item.foods = updatedData;
        //         // console.log("item: ",item)
        //     }
        //     return item;
        // })
        // console.log("updatedDiaryDetails: ", updatedDiaryDetails)
        const tempMealData = {foods:updatedData, mealType:mealType };
        // const tempDiaryData = {...diaryData, diaryDetails:updatedDiaryDetails};
        // console.log("tempDiaryData: ", tempDiaryData)
        setMealData(tempMealData);
        // setDiaryData(tempDiaryData)
        setDisabledButtons(disabledButtons.filter((id) => id !== foodID));
    }


    const getServingValue = (foodID) =>{
        const food = mealData.foods.find((item)=>item.foodID===foodID)
        return parseInt(food.servingSize);
    }
    
/**
data structure

{ date:"", meal_type:"",food_id:"",serving_data: {amount:200} }


FoodDiary
--------------
[
    {
        date:"10/1/2023",
        diaryDetails: [
            {
                type:"breakfast",
                foods: [
                    {
                        foodID:"1",
                        servingSize:""
                    },
                    {
                        foodId:"2",
                        servingSize:""
                    },

                ]
            },
            {
                type:"lunch",
                foods: [
                    {
                        foodID:"",
                        servingSize:""
                    },
                    {
                        foodId:"",
                        servingSize:""
                    },

                ]
            },
        ]
    },
    {

    }
]

 
 */

const handleSaveField = (food_id) =>{
    // setLoading(true);
    if (food_id) {
        console.log("mealData from save: ", mealData);
        const food = mealData.foods.find(item => item.foodID===food_id);
        if (food.isExists) {
            const formatedData = {serving_data: {"amount":food.servingSize, "unit":"g"}};
            updateDiaryData(food.rowId, formatedData).then((result)=>{
                console.log("update result: ", result);
                if (result) {
                    setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, food_id]);
                    alert("Successfully updated the item!");
                }
                // setLoading(false);
            })
            
        }else{
            const formatedData = {date:currentDateCalender, meal_type:mealType, food_id:food.foodID, serving_data: {"amount":food.servingSize, "unit":"g"} }
            console.log("formatedData: ", formatedData);
            storeDiaryFullData(formatedData).then((result)=>{
                console.log("store result: ", result);
                if(result){
                    setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, food_id]);
                    alert("Successfully added the item!");
                }
                // setLoading(false);
            })
        }
    }

}
   
/*
    const storeData=()=>{
        // removeDiaryFullData();
        console.log("getStoredFoodFullData: ",getStoredFoodFullData);
        const newDiaryDetails =  diaryData.diaryDetails.map((diaryD, index)=>{
            diaryD.foods = diaryD.foods.filter(food=>{
                return food.servingSize>0 && food.servingSize!==NaN;
            })
            // console.log("diaryD: ",diaryD.foods)
            return diaryD;
        })
        console.log("newDiaryDetails: ", newDiaryDetails);


        if (getStoredFoodFullData!==null) {
            const findData = getStoredFoodFullData.find(item => item.date===currentDate);
            let updatedData=null;
            if (findData) {
                updatedData = getStoredFoodFullData.map((item)=>{
                    if (item.date === currentDate) {
                        item.diaryDetails=diaryData.diaryDetails;
                    }
                    return item;
                })
            }
            else{
                updatedData = [...getStoredFoodFullData, diaryData];
            }
            console.log(updatedData[0].diaryDetails[0]);
            console.log(updatedData[0].diaryDetails[1]);
            storeDiaryFullData(updatedData).then(()=>{
                Alert.alert("Success","Successfully added!")
                navigation.navigate("Diary",{refresh:true});
            });
        }else{
            let mergeAfterFilter = diaryData;
            mergeAfterFilter.diaryDetails = newDiaryDetails;
            console.log("mergeAfterFilter: ",mergeAfterFilter);

            storeDiaryFullData([mergeAfterFilter]).then(()=>{
                Alert.alert("Success","Successfully added!")
                navigation.navigate("Diary",{refresh:true});
            });
        }
        
    }

    */

    useEffect(()=>{
        loadData();
    },[])
// console.log("mealData: ", mealData)
// console.log("Selected: ", selectedFood);

  return (
    <View style={styles.container}>
        <View style={{width:"100%", paddingBottom:5, flexDirection:'row', justifyContent:'space-between', borderColor:'#ddd', borderBottomWidth:1}}>
        <View style={{}}><Text style={{color:"#fff", fontSize:17}}>Today: {currentDate}</Text></View>
        <View>
            <Text style={{color:'#ff3', padding:3}}>Meal Type: {mealType}</Text>
            {/* <TouchableOpacity onPress={()=>{alert("storeData()")}}>
                <Text style={{backgroundColor:'green', padding:3}}>Save Data</Text>
            </TouchableOpacity> */}
        </View>
        </View>
        
        <ScrollView>
            {
               (loading!==false)?(<View><ActivityIndicator/></View>):( selectedFood.map((selectedItem, index)=>{
                // console.log("selectedItem: ", selectedItem.Energy)
                    return(
                    <View key={index}>
                        <View>
                            <View style={{flexDirection:'row', justifyContent:'space-between', paddingHorizontal:7, borderColor:'yellow', borderWidth:1}}>
                                <Text style={{fontSize:15,fontWeight:'bold',marginLeft:5,marginVertical:15,color:'#bdbdbd', flex:5}}>{selectedItem.food_name} ({selectedItem.serving_size.amount} {selectedItem.serving_size.unit}  {numberFix(selectedItem.food_nutrients.calories)} Cal)</Text>
                                
                                <View style={{flex:1, justifyContent:'center'}}>
                                    <TextInput disabled={disabledButtons.includes(selectedItem.id)} placeholder='Total (g)' style={{backgroundColor:'#636466', width:'100%', height:35, color:'#ddd',borderRadius:5, textAlign:'center', }} onChangeText={text => handleServingSize(text, selectedItem.id)} value={getServingValue(selectedItem.id)}></TextInput>

                                </View>
                                {/* <TouchableScale style={{justifyContent:'center'}} onPress={()=>{alert('Delete feature loading...')}}>
                                    <FontAwesome name='times-circle' style={{fontSize:20 ,color:'#d61106', }}/>
                                </TouchableScale> */}
                                <View style={{justifyContent:'center',}}>
                                    <TouchableScale style={{marginHorizontal:8}} onPress={()=>{handleSaveField(selectedItem.id)}} disabled={disabledButtons.includes(selectedItem.id)}>
                                        <FontAwesome name='check-circle' style={{fontSize:20 ,color: disabledButtons.includes(selectedItem.id)?'#55693c':'#4fe825', }}/>
                                    </TouchableScale>

                                </View>
                            </View>
                        </View>   
                    </View>
                    )
                }))
            }
        </ScrollView>
        <Button title='Home' onPress={()=>{navigation.navigate("Diary")}}/>

    </View>
  )
}





const styles = StyleSheet.create({
  container:{
    flex: 1,
    backgroundColor: '#000',
    padding:15
},
input:{
  backgroundColor:'#ddd',
  borderColor:'#000',
  marginBottom:10,
  // marginLeft:30,
  // marginRight:30,
  marginTop:30,
  alignContent:'center',
  justifyContent:'center',
  borderRadius:5,
  paddingLeft:15,
  paddingRight:15,
},
test:{
    color:'#ddd',
    paddingLeft:20,
    paddingTop:20,
    paddingBottom:10,
    fontSize:15,
    marginLeft:15
},
test2:{
    color:'#ddd',
    // padding:20,
    fontSize:15,
    marginLeft:25,
},
input2:{
    backgroundColor:'#ddd',
    borderColor:'#000',
    marginBottom:10,
    // marginLeft:30,
    // marginRight:30,
    // marginTop:30,
    alignContent:'center',
    justifyContent:'center',
    marginLeft:25,
    // marginEnd:20,
    borderRadius:5,
    // paddingLeft:15,
    // paddingRight:15,
  },

})