import React, {useState} from 'react';
import { Button, StyleSheet, Text, View, TextInput,ScrollView, Image, Alert } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import { SupabaseConfig } from '../hooks/SupabaseConfig';


export default function Login({navigation}){
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const handleLogin = async () =>{
        const { data, error } = await SupabaseConfig.auth.signInWithPassword({
            email: email,
            password: password,
        })

        if (error) {
            alert(error.message)
        }else{
            console.log("Login Datas: ", data);

        }
    }

    return(
        <View style={styles.container}>
            <View style={{margin:20}}>
                <Image 
                            style={{height:250}}
                            source={require('../../assets/adaptive-icon.png')}/>
            </View>
            
            <View>
                <Text style={{color:'#b3b3b3', marginLeft:'12%', marginBottom:20, borderRadius:5}}>Email</Text>
                <TextInput style={{color:'#000', backgroundColor:'#ddd',paddingHorizontal:10,paddingVertical:5, marginHorizontal:'10%', marginBottom:20,borderRadius:5}} placeholder='example@example.com' onChangeText={text => setEmail(text)}></TextInput>
                <Text style={{color:'#b3b3b3', marginLeft:'12%', marginBottom:20, borderRadius:5}}>Password</Text>
                <TextInput style={{color:'#000', backgroundColor:'#ddd',paddingHorizontal:10, borderRadius:5, marginHorizontal:'10%', paddingVertical:5}} placeholder='Password' onChangeText={text => setPassword(text)}></TextInput>
                <TouchableScale>
                    <Text style={{color:'#ddd', fontWeight:'bold', marginVertical:10, textAlign:'center'}}>Forgot Password</Text>  
                </TouchableScale> 
            <View style={{paddingHorizontal:'30%'}}>
                <Button title='Login' color='#b3b3b3' onPress={()=>{handleLogin()}}>Login</Button>          
            </View>
            <TouchableScale onPress={()=>{navigation.navigate('Register')}}>
                <Text style={{color:'#ddd', fontWeight:'bold', marginHorizontal:'18%', marginVertical:10, textAlign:'center'}}>Don't Have An Account? Sign Up</Text>
            </TouchableScale>
            </View>
        </View>
    )
}


const styles = StyleSheet.create({
    container:{
      flex: 1,
      backgroundColor: '#000',
      padding:15
  },
})