import { StyleSheet, Text, View,TextInput, ScrollView,SafeAreaView,Image } from 'react-native';
import TouchableScale from 'react-native-touchable-scale';
import {FontAwesome} from '@expo/vector-icons';
import { StatusBar } from 'expo-status-bar';
// import { StreamApp } from 'expo-activity-feed';



export default function Newsfeed({navigation}) {
    return(
        <ScrollView style={styles.container}>
            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://uploads-ssl.webflow.com/618c0cb448758633f8591848/61c88b661a6368201983b479_E0JWTeCC-h9UMNFojHIKHblClsQM_B6oQtpXXOVggkIt6MngkWoJ-G27i2O78CFlqkKitfUan3o.jpeg'
                        }}/>
                        <Text style={styles.userName}>Hamza</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                            style={styles.feedImage}
                            source={{
                                uri:'https://i.ytimg.com/vi/2E6cg8c0M38/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLC7l78BPSCy7QN1g2Bob4K6RCu_mA'
                        }}/>
                </TouchableScale>
                    

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>1,242</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>215</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>

            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://yt3.ggpht.com/Id4xmPtVqZqR-FM8HQlD0zuywFy0oJBL-Vwl_dAdiENYQltjtbEQ7fPtmQInTxgCTDZCdeiCow=s900-c-k-c0x00ffffff-no-rj'
                        }}/>
                        <Text style={styles.userName}>Sam Harborne</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                        style={styles.feedImage}
                        source={{
                            uri:'https://i.ytimg.com/vi/HC5sU3PU6UU/maxresdefault.jpg'
                    }}/>
                </TouchableScale>

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>11,365</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='black' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>1,488</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>




            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcT4QxR4VIXmI1wbhBMXu1TPPW_c2svMP3lXjmzg4nDuapGQjKTk2T47JryQniCQQAZauA8-hau1VOiUjz0'
                        }}/>
                        <Text style={styles.userName}>Daniel Radcliffe</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                        style={styles.feedImage}
                        source={{
                            uri:'https://i.playboard.app/p/88ba924780520652020572234dfcf4df/default.jpg'
                    }}/>
                </TouchableScale>

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>2,359</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='grey' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>864</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>





            <View style={styles.card}>
                <View style={styles.cardHeader}>
                    <View style={styles.headerLeft}>
                        <Image 
                        style={styles.userImage}
                        source={{
                            uri:'https://cdn.britannica.com/59/182359-050-C6F38CA3/Scarlett-Johansson-Natasha-Romanoff-Avengers-Age-of.jpg'
                        }}/>
                        <Text style={styles.userName}>Scarlett Johansson</Text>
                    </View>
                    <View style={styles.headerRight}>
                        <FontAwesome name='ellipsis-h' style={styles.fontawesomeIcon}/>
                    </View>
                </View>
                <TouchableScale>
                    <Image 
                        style={styles.feedImage}
                        source={{
                            uri:'https://i.ytimg.com/vi/uILhdYr_UDs/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAJqjjI4JpWZsLATWhiwsyk1VOASQ'
                    }}/>
                </TouchableScale>

                <View style={styles.cardFooter}>
                    <View style={styles.footerLeft}>
                        <View style={{flexDirection:'row'}}>
                            <FontAwesome name='heart' color='red' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>11,365</Text>
                        </View>
                        <View style={{flexDirection:'row' , marginLeft:10}}>
                            <FontAwesome name='comment' color='black' size={20}/>
                            <Text style={{marginLeft:5, fontSize:16, color:'#ddd'}}>1,488</Text>
                        </View>
                    </View>
                    <View style={styles.footerRight}>
                        <FontAwesome name='bookmark' color='grey' size={20}/>
                    </View>
                </View>
            </View>


            
        </ScrollView>
    )
}


const styles = StyleSheet.create({
    container:{
        flex: 1,
        backgroundColor: '#000',
    },
    card:{
        backgroundColor:'#183c57',
        margin:10,
        padding:10,
        borderRadius:10,
    },
    cardHeader:{
        flexDirection:'row',
        justifyContent:'space-between',
    },
    headerLeft:{
        flexDirection:'row'
    },
    userImage:{
        width:50,
        height:50,
        borderRadius:50/2,
    },
    userName:{
        fontWeight:'bold',
        marginLeft:10,
        marginTop:15,
        color:'#ddd'
    },
    fontawesomeIcon:{
        fontSize:20,
        color:'#000',
        marginTop:15,
        marginRight:10
    },
    feedImage:{
        height:200,
        borderRadius:10,
        marginVertical:10,
    },
    cardFooter:{
        flexDirection:'row',
        justifyContent:'space-between',
        paddingVertical:5,
        paddingLeft:5
    },
    footerLeft:{
        flexDirection:'row',
    },
})