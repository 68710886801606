import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from "react-native";

import { DrawerContentScrollView, DrawerItemList } from "@react-navigation/drawer"

import { screenHeight } from '../../Utils/Dimensions';
import { SupabaseConfig } from '../../hooks/SupabaseConfig';

function DrawerContent(props) {
  //  console.log(screenHeight);
  async function signOut() {
    const { error } = await SupabaseConfig.auth.signOut()
  }

  return (
    <View style={{ height:'100%', backgroundColor:'#000'}}>
      
      <DrawerContentScrollView {...props}>
      <View style={{backgroundColor:'#000', paddingTop:20, paddingBottom:20, marginBottom:10}}>
        <Text style={{textAlign:'center', fontSize:30, fontWeight:'bold', color:'#9e5721'}}>ADONIS</Text>
      </View>
        <View style={{}}>
          <DrawerItemList {...props} />
        </View>
        

      </DrawerContentScrollView>
      <View style={{padding:15, width:'100%', justifyContent:'center', flexDirection:'row'}}>
          <TouchableOpacity onPress={() => signOut()}>
            <Text style={{color:'#ddd', fontSize:19, fontWeight:'bold'}}>Logout</Text>
          </TouchableOpacity>
        </View>
    </View>
    
  )
}

export default DrawerContent