import React from 'react'
import { View, Text, StyleSheet } from "react-native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Diet from '../Diet';
import BottomTabNavigation from './BottomTabNavigation';
import Journal from '../Journal';
import Profile from '../Profile';
import DrawerContent from './DrawerContent';
import Meditation from '../Meditation';
import Newsfeed from '../Newsfeed';
import Download from '../Download';
import Chat from '../Chat';


const Drawer = createDrawerNavigator();


function DrawerNavigation({navigation}) {
  return (
    // <Drawer.Navigator drawerContent={ props => <DrawerContent {...props} /> }>
    //   <Drawer.Screen name='Feed' component={Feed} />
    // </Drawer.Navigator>
    <Drawer.Navigator drawerContent={ props => <DrawerContent {...props} /> } 
    screenOptions={{
      headerStyle:{backgroundColor:'#000'}, 
      headerTintColor:'#fff', 
      headerTitleAlign:'center',
      drawerLabelStyle:{fontSize:19, fontWeight:'bold'},
      drawerActiveBackgroundColor:'#b2b1b9',
      drawerActiveTintColor:'#000',
      drawerInactiveTintColor:'#ddd'
      }} >
      <Drawer.Screen
        name="Home1"
        component={BottomTabNavigation}
        options={{ drawerLabel: 'Home' }}
      />
      <Drawer.Screen
        name="Profile"
        component={Profile}
        options={{ drawerLabel: 'Profile' }}
      />
      <Drawer.Screen
        name="Journal"
        component={Journal}
        options={{ drawerLabel: 'Journal' }}
      />
      <Drawer.Screen
        name="Diet"
        component={Diet}
        options={{ drawerLabel: 'Diet' }}
      />
      <Drawer.Screen
      name="Meditation"
      component={Meditation}
      options={{ drawerLabel: 'Meditation' }}
      />
      <Drawer.Screen
      name="Newsfeed"
      component={Newsfeed}
      options={{ drawerLabel: 'Newsfeed' }}
      />
      <Drawer.Screen
      name="Chat"
      component={Chat}
      options={{ drawerLabel: 'Chat' }}
      />
      
    </Drawer.Navigator>
  )
}

export default DrawerNavigation