import React,{useState,useCallback, useEffect} from 'react';
import { StatusBar } from 'expo-status-bar';
import { Button, StyleSheet, Text, View,ScrollView, Image, FlatList, ImageBackground,RefreshControl, TouchableOpacity, ActivityIndicator, } from 'react-native';
import {FontAwesome} from '@expo/vector-icons';
import TouchableScale from 'react-native-touchable-scale';
import { LinearGradient } from 'expo-linear-gradient';
import CircularProgress from "react-native-circular-progress-indicator";
import * as Progress from 'react-native-progress';
import { LineChart, ProgressChart } from "react-native-chart-kit";
import { screenHeight, screenWidth } from '../Utils/Dimensions';
import WeightModal from '../components/WeightModal';
import { getWeightData, removeWeightData } from '../hooks/WeightHook';
import { getDayNameByDate } from '../Utils/DateTime';


const DATA = [
    {
      name: 'Macros',
      img: require('../../assets/male.jpg'),
      id:1
    },
    {
      name: 'Heart Healthy',
      img: require('../../assets/male.jpg'),
      id:2
    },{
      name: 'Low Carb',
      img: require('../../assets/male.jpg'),
      id:3
    },
  ]

function Dashboard({navigation}) {
    const [loading, setLoading] = useState(true)
    const [refreshing, setRefreshing] = useState(false);
    const [modalOpen, setOpenModel] = useState(false);
    const [weightData, setWeightData] = useState(null);

    const [progress, setProgress] = useState(0);
    const [indeterminate, setIndeterminate] = useState(true);
    
    const onRefresh = useCallback(() => {
        setRefreshing(true);
        // console.log("hello")
        loadData()
        setRefreshing(false)
    }, [refreshing]);

    const handleModalOpen=(val)=>{
        setOpenModel(val)
    }

    const loadData = ()=>{
        // console.log(getDayNameByDate('2022-05-01'));
        // removeWeightData();
        // setLoading(false)
        // setWeightData({day:["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"], weight:[ 20, 25, 30, 27, 25, 27, 28 ]})
        getWeightData().then((data)=>{
            console.log("data from then: ",data)
            let weightTemp = [];
            let dayTemp = [];
            if (data) {
                data.forEach(item=>{
                    // console.log("item.date: ", item.date)
                    weightTemp.push(parseFloat(item.weight));

                    dayTemp.push(getDayNameByDate(item.date));
                })
                setWeightData({day:dayTemp, weight:weightTemp})
                console.log("Data: ", {day:dayTemp, weight:weightTemp})
            }
            setLoading(false);
        })
    }


    const animate=()=> {
        let progress = 0;
        setProgress(progress);
        setTimeout(() => {
          setIndeterminate(false);
          setInterval(() => {
            progress += Math.random() / 5;
            if (progress > 1) {
              progress = 1;
            }
            setProgress(progress);
          }, 500);
        }, 1500);
      }

    

    useEffect(() => {
        const focusListener = navigation.addListener('focus', ()=>{
            setLoading(true);
            loadData();
            animate();
        });
        return focusListener;
        // return () => {
        //     focusListener.remove();
        //   };
    }, [navigation])

    useEffect(()=>{
        if (modalOpen===false) {
            setLoading(true);
            loadData();
            animate();
        }
        
    },[modalOpen])
    
    // if (loading!==false) {
    //     return <ActivityIndicator/>;
    // }

    // console.log("Image: "+ DATA[0].name);
  return (

    <ScrollView style={{backgroundColor:'#000'}} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
    refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={onRefresh}
        />
      }
    >
        
        <View showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
            <View style={{marginTop: 20}}>

                <ScrollView
                style={{width: '100%'}}
                contentContainerStyle={{ flexGrow: 1, paddingRight: 10, /*flexDirection: 'row-reverse'*/ }}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                >

                    
                    {/* First Card */}
                    <TouchableScale key={DATA[0].id} activeOpacity={1} onPress={() => alert(DATA[0].name)} activeScale={0.98} tension={100} friction={10}>
                        <View style={Styles.card1_background}>
                            <Text style={Styles.card1_title}>{DATA[0].name}</Text>
                            <View style={{paddingTop:10, paddingBottom:10, width:'100%'}}>
                                <View style={{paddingBottom:10, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Carbodydrates</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>74/327g</Text>
                                    </View>
                                    <Progress.Bar progress={0.74} width={null} color={'#0fd3d3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                                <View style={{paddingBottom:10, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Fat</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>164/327g</Text>
                                    </View>
                                    <Progress.Bar progress={164/327} width={null} color={'#e86bcd'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                                <View style={{paddingBottom:5, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Protein</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>100/300g</Text>
                                    </View>
                                    <Progress.Bar progress={(200/300)} width={null} color={'#e08712'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                            </View>


                            {/* <View style={[Styles.card_row, {width:'100%'}]}>
                                <View style={[Styles.card_col]}>
                                    <Text style={[Styles.card_subheading, {color:'#2c8f8f'}]}>Carbodydrates</Text>
                                    <View style={{paddingVertical:10, }}>
                                    <CircularProgress
                                            value={64}
                                            radius={50}
                                            duration={1500}
                                            progressValueColor={'#ecf0f1'}
                                            maxValue={327}
                                            title={'/327g'}
                                            titleColor={'white'}
                                            titleStyle={{fontWeight: 'bold'}}
                                            activeStrokeColor={'#2c8f8f'}
                                            onPress={()=>{}}
                                            />
                                    </View>
                                    <Text style={Styles.progressbar_sub_text}>263g left</Text>

                                </View>
                                <View style={[Styles.card_col]}>
                                <Text style={[Styles.card_subheading, {color:'#e86bcd'}]}>Fat</Text>

                                <View style={{paddingVertical:10}}>
                                    <CircularProgress
                                        value={64}
                                        radius={50}
                                        duration={1500}
                                        progressValueColor={'#ecf0f1'}
                                        maxValue={327}
                                        title={'/327g'}
                                        titleColor={'white'}
                                        titleStyle={{fontWeight: 'bold'}}
                                        activeStrokeColor= {'#e86bcd'}
                                        />
                                </View>
                                <Text style={Styles.progressbar_sub_text}>263g left</Text>

                                </View>
                                <View style={[Styles.card_col]}>
                                <Text style={[Styles.card_subheading, {color:'#e08712'}]}>Protein</Text>
                                <View style={{paddingVertical:10}}>
                                    <CircularProgress
                                        value={64}
                                        radius={50}
                                        duration={1500}
                                        progressValueColor={'#ecf0f1'}
                                        maxValue={327}
                                        title={'/327g'}
                                        titleColor={'white'}
                                        titleStyle={{fontWeight: 'bold'}}
                                        activeStrokeColor={'#e08712'}
                                        /> 
                                </View>
                                <Text style={Styles.progressbar_sub_text}>263g left</Text>
                                </View>

                            </View> */}

                        </View>
                    </TouchableScale>
                        
                        {/* Second Card */}
                    <TouchableScale key={DATA[1].id} activeOpacity={1} onPress={() => alert(DATA[1].name)} activeScale={0.98} tension={100} friction={10}>
                        <View style={Styles.card1_background}>
                            <Text style={Styles.card1_title}>{DATA[1].name}</Text>
                            <View style={{paddingTop:10, paddingBottom:10, width:'100%'}}>
                                <View style={{paddingBottom:10, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Fat</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>18/87g</Text>
                                    </View>
                                    <Progress.Bar progress={0.3} width={null} color={'#179c78'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                                <View style={{paddingBottom:10, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Sodium</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>977/2300mg</Text>
                                    </View>
                                    <Progress.Bar progress={0.42} width={null} color={'#179c78'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                                <View style={{paddingBottom:5, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Cholesterol</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>70/300mg</Text>
                                    </View>
                                    <Progress.Bar progress={0.33} width={null} color={'#179c78'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                            </View>
                            
                            

                        </View>
                    </TouchableScale>

                        {/* Third Card */}
                    <TouchableScale key={DATA[2].id} activeOpacity={1} onPress={() => alert(DATA[2].name)} activeScale={0.98} tension={100} friction={10}>
                        <View style={Styles.card1_background}>
                            <Text style={Styles.card1_title}>{DATA[2].name}</Text>
                            <View style={{paddingTop:10, paddingBottom:10, width:'100%'}}>
                                <View style={{paddingBottom:10, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Carbodydrates</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>18/87g</Text>
                                    </View>
                                    <Progress.Bar progress={0.3} width={null} color={'#4bb5e3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                                <View style={{paddingBottom:10, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Sugar</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>977/2300mg</Text>
                                    </View>
                                    <Progress.Bar progress={0.42} width={null} color={'#4bb5e3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                                <View style={{paddingBottom:5, paddingTop:5}}>
                                    <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Fibar</Text>
                                        <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>70/300mg</Text>
                                    </View>
                                    <Progress.Bar progress={0.33} width={null} color={'#4bb5e3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                </View>
                            </View>
                            

                        </View>
                    </TouchableScale>
                </ScrollView>
            </View>
            <View style={{marginTop:20}}>
                <View style={Styles.row}>
                    <TouchableScale style={[Styles.col1]} onPress={()=>{navigation.navigate("MyWorkout")}}>
                        
                        <View style={{flexDirection:'row', justifyContent:'space-between'}}>
                            <Text style={[Styles.subheading, {color:'#ddd', fontSize:20}]}>Exercise</Text>
                            <TouchableScale style={{justifyContent:'center'}} onPress={()=>{navigation.navigate("AddExercise")}}>
                                <FontAwesome name='plus' color='#ddd' size={20} fontWeight={'bold'} />
                            </TouchableScale>
                        </View>
                            <View style={{paddingTop:5, paddingBottom:5, justifyContent:'space-evenly', height:'90%'}}>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{justifyContent:'center'}}>
                                        <FontAwesome name='fire' color='#e08712' size={20} fontWeight={'bold'} padding={4}  />
                                    </View>
                                    
                                    <Text style={{fontSize:19, fontWeight:'bold', color:'#99acad', padding:2, marginLeft:10}}>24 cal</Text>
                                </View>
                                <View style={{flexDirection:'row'}}>
                                    <View style={{justifyContent:'center'}}>
                                        <FontAwesome name='clock-o' color='#e08712' size={20} fontWeight={'bold'} padding={4}  />
                                    </View>
                                    <Text style={{fontSize:18, color:'#84898a', padding:2, marginLeft:10, fontWeight:'bold'}}>00:00 hr</Text>
                                </View>
                                </View>
                            </TouchableScale>
                            
                     
                    <TouchableScale style={[Styles.col1, {padding:0}]} onPress={()=>{navigation.navigate("MeditationUnlock")}}>
                        <ImageBackground source={require('../../assets/backgrounds/reading-workout.jpeg')} imageStyle={{borderRadius: 15}} style={{width:'100%', height:'100%'}}  >
                            <LinearGradient colors={['rgba(0,0,0,0.1)', 'rgba(0,0,0,0.6)']} style={Styles.card1_gradient}>
                                {/* <View>
                                    <Text style={Styles.subheading}>Steps</Text>
                                </View> */}
                                <View >
                                    <Text style={{fontSize:25, fontWeight:'bold', color:'#ffffff'}}>Free Classes</Text>
                                    
                                    {/* <Text style={{fontSize:15, color:'#84898a', padding:2, fontWeight:'bold'}}>Goal: 10,000 steps</Text>
                                    <View style={{paddingTop:10}}>
                                    <Progress.Bar progress={0.3} width={null} color={'#d13f72'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                    </View> */}
                                    
                                </View>
                            </LinearGradient>
                        </ImageBackground>
                    </TouchableScale>
                    
                </View>
            </View>

            <View style={{marginTop:20}}>
                <View style={Styles.row}>
                    
                    {/* <View> */}
                    <TouchableScale style={[Styles.col1, {padding:0}]} onPress={()=>{navigation.navigate('Meditation')}}>
                        <ImageBackground source={require('../../assets/backgrounds/meditation.jpeg')} imageStyle={{borderRadius: 15}} style={{width:'100%', height:'100%'}}  >
                            <LinearGradient colors={['rgba(0,0,0,0.3)', 'rgba(0,0,0,0.8)']} style={Styles.card1_gradient}>
                                <View >
                                    <Text style={{fontSize:22, fontWeight:'bold', color:'#ffffff'}}>MEDITATION</Text>
                                </View>
                            </LinearGradient>
                        </ImageBackground>
                    </TouchableScale>
                    {/* </View> */}
                    <View style={[Styles.col1]}>
                        <View style={{flexDirection:'row', justifyContent:'center'}}>
                            <Text style={[Styles.subheading, {color:'#ddd', fontSize:22}]}>Daily Journal</Text>
                            
                        </View>
                        <View style={{paddingTop:5, paddingBottom:5, justifyContent:'space-evenly', height:'90%'}}>
                            <View style={{flexDirection:'row', justifyContent:'center'}}>
                                {/* <View style={{justifyContent:'center'}}>
                                    <FontAwesome name='fire' color='#e08712' size={20} fontWeight={'bold'} padding={4}  />
                                </View> */}
                                <TouchableScale style={{justifyContent:'center'}} onPress={()=>{navigation.navigate('Journal')}}>
                                    <FontAwesome name='plus' color='#ddd' size={37} fontWeight={'bold'} />
                                </TouchableScale>
                                
                                {/* <Text style={{fontSize:19, fontWeight:'bold', color:'#99acad', padding:2}}>Add Diary</Text> */}
                            </View>
                            
                        </View>
                    </View>
                </View>
            </View>

            <View style={{marginTop:20, marginBottom:20}}>
                <View style={{margin:15}}>
                    <Text style={Styles.card1_title}>Progress</Text>
                </View>
                <View style={{}}>
                    <ScrollView
                    style={{width: '100%'}}
                    contentContainerStyle={{ flexGrow: 1, paddingRight: 10, /*flexDirection: 'row-reverse'*/ }}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    >
                        {/* <TouchableScale key={DATA[1].id} activeOpacity={1} onPress={() => alert(DATA[1].name)} activeScale={0.98} tension={100} friction={10}> */}
                            <View style={[Styles.card1_background, {padding:0, backgroundColor:'rgba(0, 0, 0, 0.43)'}]}>
                                <View style={{flexDirection:'row', justifyContent:'space-between', width:'100%', paddingLeft:15, paddingRight:15, paddingTop:10}}>
                                    <Text style={[Styles.subheading, {fontSize:18}]}>Weight</Text>
                                    <TouchableScale style={{justifyContent:'center'}} onPress={()=>{handleModalOpen(true)}}>
                                        <FontAwesome name='plus' color='#b2b1b9' size={20} fontWeight={'bold'} />
                                    </TouchableScale>
                                </View>
                                <WeightModal modalIsOpen={modalOpen} handleModalOpen={handleModalOpen} />
                                <View style={{paddingTop:10, paddingBottom:10, width:'100%'}}>
                                    <View style={{paddingBottom:10, paddingTop:5}}>
                                        {
                                            (loading===true)?(<View><ActivityIndicator/></View>):(
                                                (weightData===null)?(<View style={{padding:10}}><Text style={{ textAlign:'center', color:'#bdbdbd'}}>No data stored to generate graph..</Text></View>):(
                                                    <View>
                                                    <LineChart
                                                        data={{
                                                        labels: weightData.day, //["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                                                        datasets: [
                                                            {
                                                            data: weightData.weight //[ 20, 25, 30, 27, 25, 27, 28 ]
                                                            }
                                                        ],
                                                        }}
                                                        width={screenWidth*0.90} // from react-native
                                                        height={screenHeight*0.31}
                                                        // yAxisLabel="$"
                                                        // yAxisSuffix="k"
                                                        yAxisInterval={1} // optional, defaults to 1
                                                        
                                                        fromZero={true}
                                                        chartConfig={{
                                                        //   backgroundColor: "rgba(0, 0, 0, 0.6)",
                                                        //   backgroundGradientFrom: "rgba(24, 60, 87, 0.13)",
                                                        //   backgroundGradientTo: "rgba(24, 60, 87, 0.13)",
                                                        //   decimalPlaces: 2, // optional, defaults to 2dp
                                                        //   backgroundGradientFrom: "#1E2923",
                                                        backgroundGradientFromOpacity: 0,
                                                        //   backgroundGradientTo: "#08130D",
                                                        backgroundGradientToOpacity: 0,
                                                        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                                                        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                                                        propsForDots: {
                                                            r: "4",
                                                            strokeWidth: "2",
                                                            stroke: "#ffa726"
                                                        }
                                                        }}
                                                        // bezier
                                                        style={{
                                                        //   marginVertical: 8,
                                                        borderRadius: 16,
                                                        //   width:'100%'
                                                        // backgroundColor:'rgb(80,20,30)'
                                                        }}
                                                    />
                                                    </View>
                                                )
                                                
                                            )
                                        }
                                    
                                        {/* <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Fat</Text>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>18/87g</Text>
                                        </View>
                                        <Progress.Bar progress={0.3} width={null} color={'#179c78'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} /> */}
                                    </View>
                                    
                                    
                                </View>
                                
                                

                            </View>
                        {/* </TouchableScale> */}
                        {/* <TouchableScale key={DATA[2].id} activeOpacity={1} onPress={() => alert(DATA[2].name)} activeScale={0.98} tension={100} friction={10}> */}
                            <View style={Styles.card1_background}>
                                <Text style={[Styles.subheading, , {fontSize:18}]}>Steps</Text>
                                <View style={{paddingTop:10, paddingBottom:10, width:'100%'}}>
                                    <View style={{paddingBottom:10, paddingTop:5}}>
                                        <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Carbodydrates</Text>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>18/87g</Text>
                                        </View>
                                        <Progress.Bar progress={0.3} width={null} color={'#4bb5e3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                    </View>
                                    <View style={{paddingBottom:10, paddingTop:5}}>
                                        <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Sugar</Text>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>977/2300mg</Text>
                                        </View>
                                        <Progress.Bar progress={0.42} width={null} color={'#4bb5e3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                    </View>
                                    <View style={{paddingBottom:5, paddingTop:5}}>
                                        <View style={{ flexDirection:'row', justifyContent:'space-between', paddingBottom:5}}>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>Fibar</Text>
                                            <Text style={{color:'#99acad', fontSize:18, fontWeight:'bold'}}>70/300mg</Text>
                                        </View>
                                        <Progress.Bar progress={0.33} width={null} color={'#4bb5e3'} borderColor={'#000'} unfilledColor={'#000'} borderRadius={10} height={11} />
                                    </View>
                                </View>
                                

                            </View>
                        {/* </TouchableScale> */}
                    </ScrollView>
                </View>

            </View>
        </View>
    </ScrollView>
  )
}

const Styles = StyleSheet.create({

    card1_gradient:{
        // position: 'absolute',
        padding:15,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15
    },

    card1_background:{
        width: screenWidth*0.90,
        height: screenHeight * 0.35,
        alignItems: 'flex-start',
        alignSelf: 'center', 
        // justifyContent: 'flex-end',
        padding: 15,
        marginBottom: 20,
        marginLeft: 12,
        // position:'relative',
        backgroundColor: '#183c57',  //old rgba(24, 60, 87, 0.63)
        // borderWidth:0.1,
        borderRadius: 15,
        // shadowColor: 'rgba(0, 0, 0, 0.6)',
        // shadowOffset:'0 4 30',
    },
    card1_title:{
        color: "white",
        fontWeight: 'bold',
        fontSize:25,
    },
    card_row:{
        // flex:3,
        flexDirection:'row',
        // flexWrap:'wrap',
        justifyContent:'space-between',
        alignItems:'center',
        
    },
    card_col: {
        flex: 1,
        paddingVertical:20,
        textAlign:'center',
        alignItems:'center', 
        justifyContent:'center',
    },
    card_subheading:{
        color:'#b2b1b9',
        // textAlign:'center',
        fontWeight:'bold',
        fontSize:15,
        paddingVertical: 5,
    },
    progressbar_sub_text:{
        color:'#b6bfbf',
        textAlign:'center',
        paddingVertical:5,
        fontSize:15,
    },
    row:{
        flexDirection:'row',
        justifyContent:'space-around',
        height:screenHeight*0.22,
    },
    col1:{
        flex:1,
        maxWidth:'45%',
        padding:15,
        borderRadius:15,
        backgroundColor:'#183c57',  //old rgba(24, 60, 87, 0.63)
        opacity: 0.6
    },
    col2:{
        flex:2,
    },
    subheading:{
        color:'white',
        fontSize:17,
        fontWeight:'bold',
        // paddingVertical:5,
    }
})

export default Dashboard